<style lang="scss">
.basisplatzierung {
  .format-box {
    margin-bottom: 20px;
    .col-lg-3 {
      justify-content: center;
      align-self: center;
    }
    img {
      display: block;
      margin: 0 auto;
      max-height: 120px;
    }
    .tabelle-box {
      .headline {
        text-transform: none;
        strong {
          display: block;
          text-transform: uppercase;
        }
      }
    }
  }
  .hinweis-box {
    padding: 15px;
    background: #eee;
  }
  .karte-bild-box {
    height: 350px;
    img {
      display: block;

      margin: 0 auto;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
    .karte-stmk {
      width: 90%;
    }
  }
  .image-box {
    margin: 50px 0;
  }

  .themen-box {
    margin: 50px 0;
    .row {
      justify-content: center;
      align-self: center;
    }
    .col-lg-6,
    .col-lg-3 {
      margin-bottom: 30px;
      .bild-box {
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 300px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        &:after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-color: rgba($color: #000000, $alpha: 0.5);
          -webkit-transition: all 500ms ease;
          -moz-transition: all 500ms ease;
          -ms-transition: all 500ms ease;
          -o-transition: all 500ms ease;
          transition: all 500ms ease;
        }
        .txt {
          width: 100%;
          position: absolute;
          text-align: center;
          left: 0;
          top: 50%;
          z-index: 2;
          color: #fff;
          font-size: 150%;
          height: 40px;
          margin-top: -20px;
          span {
            display: block;
            font-size: 50% !important;
            padding: 0 20px;
          }
        }
        &:hover {
          &:after {
            background-color: rgba($color: #000000, $alpha: 0);
          }
          .txt {
            opacity: 0;
          }
        }
      }
    }
  }
}
.outer-slider {
  .inner-box {
    padding-top: 0px !important;
    .kat {
      display: none !important;
    }
  }
}
</style>

<template>
  <div class="basisplatzierung content container">
    <h1>Basisplatzierung</h1>
    <div class="row">
      <div class="col-lg-8 col-md-10">
        <p class="intor-txt">Lebensnahe Umfelder sorgen für maximale Aufmerksamkeit. Platzieren Sie Ihre Werbebotschaft zielgruppengenau nach den Interessen unserer Leserinnen und Leser – wochentags und in der Sonntagsausgabe.</p>
      </div>
    </div>
  </div>

  <div class="bg-blue-light margin-b-m">
    <div class="wrapper-xxl outer-slider">
      <!-- -->
      <div class="print-overview-slider">
        <!-- <Slider :delay="4000" :items="{ 768: 2, 1024: 3, 1400: 3, 1600: 3 }" easing="linear" :speed="500" autoplay noclone>-->
        <Slider :delay="4000" :items="{ 768: 2, 1024: 2, 1400: 3, 1600: 3 }" easing="linear" :speed="500" autoplay>
          <template #navigation="{ left, right }">
            <div class="slider-navi-outer">
              <div @click="right"><span class="material-icons arrow-btn arrow-left">arrow_back_ios</span></div>
              <div @click="left"><span class="material-icons arrow-btn arrow-right">arrow_forward_ios</span></div>
            </div>
          </template>

          <template #items>
            <!--<SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/11a_Besser-Leben-1-von-2_Basis.jpg" alt="Basisplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Basisplatzierung</div>
                <router-link :to="{ name: 'Basisplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/11a_Besser-Leben-2-von-2_Basis.jpg" alt="Basisplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Basisplatzierung</div>
                <router-link :to="{ name: 'Basisplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>-->

            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/14_Mobilitaet_Basis.jpg" alt="Basisplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Basisplatzierung</div>
                <router-link :to="{ name: 'Basisplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/15_Wohnen_Basis.jpg" alt="Basisplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Basisplatzierung</div>
                <router-link :to="{ name: 'Basisplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/16_Beruf-und-Bildung-Wochentags_Basis.jpg" alt="Basisplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Basisplatzierung</div>
                <router-link :to="{ name: 'Basisplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/18_Sport-1-von-2_Basis.jpg" alt="Basisplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Basisplatzierung</div>
                <router-link :to="{ name: 'Basisplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/18_Sport-2-von-2_Basis.jpg" alt="Basisplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Basisplatzierung</div>
                <router-link :to="{ name: 'Basisplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/21_Kultur-1-von-2_Basis.jpg" alt="Basisplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Basisplatzierung</div>
                <router-link :to="{ name: 'Basisplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/21_Kultur-2-von-2_Basis.jpg" alt="Basisplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Basisplatzierung</div>
                <router-link :to="{ name: 'Basisplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
          </template>
        </Slider>
      </div>
      <!-- -->
    </div>
  </div>

  <div class="basisplatzierung content container">
    <div class="row justify-content-center">
      <div class="col-lg-12 col-md-12 text-center">
        <strong>Besser Leben</strong>
        <p>
          Dienstag „Recht & Geld“ (Ombudsfrau)
        </p>
      </div>
    </div>
    <div class="text-center margin-t-s">
      <CollapsibleButton link="basisplatzierungen-tabellen" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Ihre Werbemöglichkeiten</CollapsibleButton>
    </div>
    <Collapsible link="basisplatzierungen-tabellen">
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Millimeterpreis 4c</strong>
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">20,99</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">15,05</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">9,21</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">22,21</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">15,71</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">9,61</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3"></div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Aufschlagseite Sport, Kultur</strong>
              Fixformat: 47 x 45 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">1.227,92</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">880,43</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">538,79</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">1.299,29</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">919,04</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">562,19</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x45.svg" alt="47x45" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Aufschlagseite Sport, Kultur</strong>
              Fixformat: 98 x 45 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">2.455,83</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">1.760,85</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.077,57</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">2.598,57</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">1.838,07</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.124,37</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x45.svg" alt="47x45" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Aufschlagseite Sport, Kultur</strong>
              Fixformat: 98 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">7.367,49</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">5.282,55</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">3.232,71</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">7.795,71</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">5.514,21</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">3.373,11</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="47x45" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Aufschlagseite Sport, Kultur</strong>
              Fixformat: 47 x 260 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">7.094,62</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">5.086,90</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">3.112,98</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">7.506,98</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">5.309,98</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">3.248,18</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x260.svg" alt="47 x 260" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Aufschlagseite Sport, Kultur</strong>
              Fixformat: 149 x 90 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">7.367,49</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">5.282,55</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">3.232,71</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">7.795,71</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">5.514,21</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">3.373,11</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-149x90.svg" alt="149x90" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Aufschlagseite Sport, Kultur</strong>
              Fixformat: 200 x 65 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">7.094,62</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">5.086,90</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">3.112,98</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">7.506,98</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">5.309,98</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">3.248,18</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x65.svg" alt="200x65" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Aufschlagseite Sport, Kultur</strong>
              Fixformat: 98 x 260 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">14.189,24</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">10.173,80</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">6.225,96</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">15.013,96</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">10.619,96</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">6.496,36</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x260.svg" alt="98x260" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Aufschlagseite Sport, Kultur</strong>
              Fixformat: 200 x 45 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">4.911,66</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">3.521,70</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.155,14</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">5.197,14</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">3.676,14</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.248,74</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x45.svg" alt="200x45" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/8 Seite</strong>
              Fixformat: 47 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">2.833,65</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">2.031,75</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.243,35</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">2.998,35</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">2.120,85</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.297,35</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x135.svg" alt="47x135" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/4 Seite</strong>
              Fixformat: 98 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">5.667,30</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">4.063,50</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.486,70</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">5.996,70</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">4.241,70</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.594,70</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="98x135" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/2 Seite</strong>
              Fixformat: 200 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">11.334,60</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">8.127,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">4.973,40</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">11.993,40</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">8.483,40</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">5.189,40</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x135.svg" alt="200x135" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/1 Seite</strong>
              Fixformat: 200 x 275 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">23.089,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">16.555,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">10.131,00</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">24.431,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">17.281,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">10.571,00</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x275" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Besser leben</strong>
              Fixformat: 47 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">2.998,35</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">2.120,85</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.297,35</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x135.svg" alt="47x135" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Besser leben</strong>
              Fixformat: 98 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">5.996,70</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">4.241,70</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.594,70</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="98x135" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Besser leben</strong>
              Fixformat: 200 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">11.993,40</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">8.483,40</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">5.189,40</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x135.svg" alt="200x135" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Top-Tipp-Fenster</strong>
              Fixformat: 65 x 80 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">1.170,51</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">882,87</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">528,49</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-65x80.svg" alt="65x80" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Medientipp Samstag <small>(Kino / Streaming, Podcast / Musik) </small></strong>
              Fixformat: 72 x 60 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">1.998,90</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">1.413,90</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">864,90</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-72x60.svg" alt="72x60" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Wochenendtipp Samstag <small>(Aviso, Kulinarik & Wandern) </small></strong>
              Fixformat: 72 x 80 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">2.665,20</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">1.885,20</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.153,20</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-72x80.svg" alt="72x80" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Wochenendtipp Sonntag <small>(Lifestyle, Familie & Tiere) </small></strong>
              Fixformat: 72 x 80 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">2.665,20</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">1.885,20</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.153,20</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-72x80.svg" alt="72x80" class="img-fluid " />
        </div>
      </div>
      <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 5 % Werbeabgabe und 20 % Umsatzsteuer. Titel-, Aufschlagseite, U4 inklusive Aufpreis.</div>
    </Collapsible>
    <!-- -->

    <!--<div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/hauptblatt/Basis-Platzierung.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarife Basisplatzierung
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>300 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';
import Slider from '@/components/util/Slider.vue';
import SliderItem from '@/components/util/SliderItem.vue';

export default defineComponent({
  components: {
    CollapsibleButton,
    Collapsible,
    Slider,
    SliderItem,
  },
});
</script>
